.popup-content {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 8px;
  button {
    a {
      text-decoration: none !important;
      color: rgba(0, 0, 0, 0.65) !important;
      &:hover {
        color: #1890ff !important;
      }
    }
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.65) !important;
    &:hover {
      color: #1890ff !important;
    }
  }
}

header {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
  position: sticky;
  background: white;
  top: 0;
  z-index: 1;
  .logo {
    height: 100%;
    margin-right: 20px;
  }
  nav {
    height: 100%;
    display: flex;
  }
  ul {
    display: flex;
    margin: 0 auto;
    height: 100%;
    text-align: center;
  }
  li {
    display: flex;
    align-items: center;
    height: 100%;
    transition: all ease-in-out 0.2s;
    border: none;
    position: relative;

    a {
      display: block;
      color: rgba(0, 0, 0, 0.65);
      width: 100%;
      padding: 0 0.5em;
      text-decoration: none;
      font-weight: bold;
      &.selected {
        color: #1890ff;
      }
    }
  }
  .link::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 2px;
    left: 50%;
    bottom: 0;
    background-color: #1890ff;
    transition: all ease-in-out 0.2s;
  }
  .link:hover {
    a {
      color: #1890ff;
    }
  }
  .link:hover::after {
    width: 100%;
    left: 0;
  }
  .logout {
    button {
      color: #1890ff !important;
      text-decoration: none !important;
    }
  }
}
