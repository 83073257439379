.App {
  text-align: center;
}
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="file"],
input[type="number"],
input[type="tel"],
textarea {
  background-color: white !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 20px 40px;
  .right {
    display: flex;
    align-items: center;

    > * {
      margin: 0 6px;
    }
  }
}
.report-print-table {
  display: none;
}

.report {
  margin: 40px;

  .report-title {
    display: flex;
    justify-content: flex-end;
  }
  .right {
    display: flex;
    align-items: center;
    margin-top: 20px;
    > * {
      margin: 0 6px;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
}
.filter {
  background-color: rgb(244, 245, 247);
  padding: 12px;
  margin-top: 20px;
  .top-section {
    display: flex;
    justify-content: space-between;
  }
  .filter-form {
    .field-wrapper {
      width: 200px;
      margin: 0 8px;
    }
  }
  .filter-fields {
    display: flex;
    flex-wrap: wrap;
  }
  .filter-heading {
    margin: 0;
  }
}
.table-wrapper {
  overflow: auto;
  border: 1px solid #e6e6e6;
  margin-top: 20px;

  table {
    border-collapse: collapse;
    min-width: 100%;
    font-size: 14px;
  }
  tr:hover {
    td {
      background: #e6f7ff;
    }
  }
  td {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
    transition: all 0.3s, border 0s;
    white-space: nowrap;
  }
  th {
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    padding: 8px;
    text-align: left;
    cursor: pointer;
    span {
      position: relative;
      &:before {
        display: block;
        height: 0px;
        position: absolute;
        right: -8px;
        width: 0px;
        bottom: 8px;
        content: " ";
        border-width: 3px;
        border-style: solid;
        border-color: transparent transparent rgb(223, 225, 230);
        border-image: initial;
        border-bottom: 3px solid rgb(223, 225, 230);
      }

      &:after {
        display: block;
        height: 0px;
        position: absolute;
        right: -8px;
        width: 0px;
        bottom: 0px;
        content: " ";
        border-width: 3px;
        border-style: solid;
        border-color: rgb(223, 225, 230) transparent transparent;
        border-image: initial;
        border-top: 3px solid rgb(223, 225, 230);
      }
    }
    &.asc {
      span:before {
        border-color: transparent transparent rgb(94, 108, 132);
        border-bottom: 3px solid rgb(94, 108, 132);
      }
    }
    &.desc {
      span:after {
        border-color: rgb(94, 108, 132) transparent transparent;
        border-top: 3px solid rgb(94, 108, 132);
      }
    }
  }
}

.slick-prev,
.slick-next {
  z-index: 20;
}
.slick-prev {
  left: -40px;
}
.slick-next {
  right: -40px;
}
.slick-prev:before,
.slick-next:before {
  font-size: 28px;
  color: rgb(200, 200, 200);
}
.slide-wrapper img {
  margin: auto;
  max-width: 700px;
}
.home {
  padding: 40px;
  margin: auto;
  max-width: 700px;
}
.spinner {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 511;
  top: 0;
  left: 0;
  svg {
    stroke: #1890ff;
  }
}
@media print {
  .report-print-table {
    display: block !important;
  }
}
